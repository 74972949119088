<template>
<div class="apCodeUpload-page-content winner-page__hero">
   <div class="main-block position-relative">
      <div class="container-xl py-lg-5">
        <div class="row  my-lg-4" :class="winnerType == 'heti' ? 'winner-bg--heti':''">
            <div class="col-12">
                <div class="winner-bg">
                    <div class="row">
                        <div class="col-12 col-lg-7 d-md-flex justify-content-center flex-column order-lg-0 order-2">
                            <div class="text-left ms-5 ps-5">
                                <h1 class="text-red pt-4 pt-lg-0 fw-bolder">Gratulálunk!</h1>              
                                <p class="text-dark-blue font-primary fw-bold">Érvényes pályázat esetén<br>nyereményed lehet<br>
                                    <!-- <span class="winner-page__hero__title text-uppercase fw-bold px-3 pt-3" v-if="winnerType == 'napi'">10 000 Ft<br>pénznyeremény<br></span>
                                    <span class="winner-page__hero__title text-uppercase fw-bold px-3 pt-3" v-if="winnerType == 'heti'">150 000 Ft<br>pénznyeremény<br></span>
                                    <span class="winner-page__hero__title text-secondary px-3 pt-3" v-if="winnerType == 'fodij'">1 db Klarstein Audrey Mini retro hűtőszekrény</span> -->
                                </p>
                            </div>
                        </div>      
                    
                        <div class="col-12 col-lg-5 align-self-center order-1 order-lg-2">
                            <div class="winnerType-bgr text-center">
                            <img v-if="winnerType == 'napi'" src="imgs/winnings/napi-nyeremeny-1x.png" alt="napi nyememény" class="img-fluid mb-2 mobile-size" width="350"/>
                                <img v-if="winnerType == 'heti'" src="imgs/winnings/heti-nyeremeny-1x.png" width="90%" alt="heti nyeremény" class="img-fluid mobile-size"/>
                                <img v-if="winnerType == 'fodij'" src="imgs/winnings/fodij-nyeremeny-1x.png" class="img-fluid mobile-size me-3">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <!-- <div class="col-12 text-center text-primary order-3">
                <p style="font-size:14px;">A képek csak illusztrációk.</p>
            </div> -->
        </div>   
    </div>    
  </div>
  <!--<Ribbon/>-->
</div>
</template>

<script>
//import Ribbon from '@/components/Ribbon.vue'

export default {
    components:{
        //Ribbon
    },
    props:['winnerType']
}
</script>